<template>
  <div>
    <h1>Order {{order_id}}</h1>
    <svg width="350" height="600" xmlns="http://www.w3.org/2000/svg" v-if="order">
     <g>
      <line :stroke="accepted_outline_colour" stroke-width="8" y1="84" x1="31" y2="201" x2="31" />
      <line :stroke="picking_outline_colour" stroke-width="8" y1="241" x1="31" y2="361" x2="31" />
      <line :stroke="ready_outline_colour" stroke-width="8" y1="401" x1="31" y2="521" x2="31" />
      <circle id="circle" class="circle_animation" r="20" cy="61" cx="31" stroke-width="8" :stroke="placed_outline_colour" :fill="placed_fill_colour"/>
      <circle id="circle" class="circle_animation" r="20" cy="221" cx="31" stroke-width="8" :stroke="accepted_outline_colour" :fill="accepted_fill_colour"/>
      <circle id="circle" class="circle_animation" r="20" cy="381" cx="31" stroke-width="8" :stroke="picking_outline_colour" :fill="picking_fill_colour"/>
      <circle id="circle" class="circle_animation" r="20" cy="541" cx="31" stroke-width="8" :stroke="ready_outline_colour" :fill="ready_fill_colour"/>
      <rect x="6" y="270" width="300" height="50" fill="white" rx="10" :stroke="incompleteColour" v-if="orderAccepted && !orderIsPicking" />
      <text x="156" y="302" width="50" height="50" font-size="20" text-anchor="middle" v-if="orderAccepted && !orderIsPicking" >{{order.orders_before}} orders ahead of yours</text>
      <text x="80" y="72" style="font-size: 32px;">Order placed</text>
      <text x="80" y="230" style="font-size: 32px;">Confirmed</text>
      <text x="80" y="393" style="font-size: 32px;">Pick in progress</text>
      <text x="80" y="553" style="font-size: 32px;">Ready to collect</text>
     </g>
    </svg>
  </div>
</template>
<script>
export default {
    data(){
        return {
            incompleteColour: "#aaaaaa",
            completedColour: "#00aa00",
            order: null,
            baseUrl: process.env.VUE_APP_API_BASE ? process.env.VUE_APP_API_BASE : "https://beta.super5ives.com",
            activeTimeout: null
        }
    },
    props: [
        "order_id",
        "digest",
    ],
    computed: {
        landscape(){ return window.innerWidth > window.innerHeight; },
        placed_outline_colour(){
            if(this.order && this.orderCollected) return this.incompleteColour;
            if(this.order) return this.completedColour;
            return this.incompleteColour;
        },
        placed_fill_colour(){
            if(this.order) return this.completedColour;
            return "transparent";
        },
        orderAccepted(){
            return this.order && (this.order.state=="process" || this.order.state=="rtd");
        },
        orderIsPicking(){
            return this.order && this.order.picking;
        },
        orderReady(){
            return this.order && this.order.state=="rtd";
        },
        orderCollected(){
            return this.order.state == 'complete';
        },
        accepted_outline_colour(){
            if(this.orderCollected) return this.incompleteColour;
            if(this.orderAccepted) return this.completedColour;
            return this.incompleteColour;
        },
        accepted_fill_colour(){
            if(this.orderCollected) return this.completedColour;
            if(this.orderAccepted) return this.completedColour;
            return "transparent";
        },
        picking_outline_colour(){
            if(this.orderCollected) return this.incompleteColour;
            if(this.orderIsPicking) return this.completedColour;
            return this.incompleteColour;
        },
        picking_fill_colour(){
            if(this.orderCollected) return this.completedColour;
            if(this.orderIsPicking) return this.completedColour;
            return "transparent";
        },
        ready_outline_colour(){
            if(this.orderCollected) return this.incompleteColour;
            if(this.orderReady) return this.completedColour;
            return this.incompleteColour;
        },
        ready_fill_colour(){
            if(this.orderCollected) return this.completedColour;
            if(this.orderReady) return this.completedColour;
            return "transparent";
        },
    },
    mounted(){
        this.setupRealtime()
    },
    methods: {
        setupRealtime(){
            if(this.realtimeSocket && this.realtimeSocket.readyState == 1) return;

            this.realtimeSocket = new WebSocket(
                this.baseUrl.replace("https://", "wss://").replace("http://", "ws://") + "/ws/super5ives/order-status");
            this.realtimeSocket.onerror = () => {
                if(window.activeTimeout) window.clearTimeout(window.activeTimeout)
                window.activeTImeout = window.setTimeout(this.setupRealtime, 10000)
            }
            this.realtimeSocket.onclose = () => {
                if(window.activeTimeout) window.clearTimeout(window.activeTimeout)
                window.activeTimeout = window.setTimeout(this.setupRealtime, 5300)
            }
            this.realtimeSocket.onopen = () => {
                this.realtimeSocket.send(JSON.stringify({"order": this.order_id, "digest": this.digest}));
            }
            this.realtimeSocket.onmessage = this.realtimeMessage;
        },
        realtimeMessage(message){
            var msg = JSON.parse(message.data);
            this.order = msg;
        }
    }
}
</script>